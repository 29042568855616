import React from "react";

const Step = ({ idx, header, graphic, isWeb }) => {
  return (
    <div
      className={`flex ${
        isWeb ? "flex-row gap-12" : "flex-col items-center"
      } py-8 w-full`}
    >
      {idx % 2 && isWeb ? graphic : null}
      <div className="flex flex-col justify-center">
        <p
          class={`bg-gradient-to-r from-t-g-s to-t-g-e bg-clip-text text-transparent font-inter font-[700] text-xl ${
            !isWeb && "text-center mb-4"
          }`}
        >
          Step {idx}
        </p>
        {!isWeb ? graphic : null}
        <p
          className={`font-inter font-[700] text-xl text-text-w max-w-xs ${
            !isWeb && "text-center max-w-xs mt-4"
          }`}
        >
          {header}
        </p>
      </div>
      {isWeb && idx % 2 === 0 && graphic}
    </div>
  );
};

export default Step;
