import React from "react";

const WrappedImg = ({ img, isWeb }) => {
  return (
    <div class="bg-dark-p p-8 items-center rounded-3xl">
      <img
        src={img}
        alt="dp"
        class={`rounded-2xl ${isWeb ? "w-80 h-80" : "w-64 h-64"}`}
      />
    </div>
  );
};

export default WrappedImg;
