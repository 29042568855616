import phone from "./img/phone.png";
import clipped from "./img/clipped.png";
import dp from "./img/dp.png";
import QRCode from "qrcode.react";
import Step from "./TutorialStep";
import OSToggle from "./OSToggle";
import "./App.css";
import { useState } from "react";
import WrappedImg from "./WrappedImg";
import a1 from "./img/a1.png";
import a2 from "./img/a2.png";
import a3 from "./img/a3.png";
import a4 from "./img/a4.png";
import a5 from "./img/a5.png";
import i1 from "./img/i1.png";
import i2 from "./img/i2.png";
import i3 from "./img/i3.png";
import i4 from "./img/i4.png";
import i5 from "./img/i5.png";
import loll from "./img/loll.png";

const vCard = `BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:Robo Dobo
N;CHARSET=UTF-8:Dobo;Robo;;;
TEL;TYPE=HOME,VOICE:9176056982
REV:2024-01-17T01:06:55.162Z
END:VCARD
`;

const phoneNum = "+1 (917) 605-6982";

function App() {
  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(ua)) {
      return "iOS";
    }
    return "Web";
  };

  const deviceType = getDeviceType();
  const [os] = useState(deviceType);
  const [visibleTodo, setVisibleTodo] = useState(
    deviceType === "Web" ? "Android" : deviceType
  );

  const ContactCard = () => (
    <div class="bg-dark-p p-8 items-center rounded-3xl">
      {deviceType === "Web" ? (
        <div class="flex flex-col items-center w-80 h-80">
          <img src={dp} alt="dp" class="w-24" />
          <p class="text-text-w font-inter font-[400] pt-4 text-nowrap">
            Robo Dobo
          </p>
          <p class="text-text-w font-inter font-[400] pb-4 text-nowrap">
            {phoneNum}
          </p>
          <QRCode size={120} value={vCard} />
          <p class="text-text-w font-inter font-[300] text-sm">
            Scan the code to add the contact
          </p>
        </div>
      ) : (
        <div class="flex flex-col items-center w-64 h-64">
          <img src={dp} alt="dp" class="w-24" />
          <p class="text-text-w font-inter font-[400] pt-4 text-nowrap">
            Robo Dobo
          </p>
          <p class="text-text-w font-inter font-[400] pb-4 text-nowrap">
            {phoneNum}
          </p>
          <a
            href={
              deviceType === "iOS"
                ? `${process.env.PUBLIC_URL}/vcard.vcf`
                : `tel:+19176056982`
            }
            download
          >
            <button className="bg-gradient-to-r from-t-g-s to-t-g-e text-white p-2 rounded-lg w-full">
              <p class="text-text-w font-inter font-[700] text-lg">
                Add contact
              </p>
            </button>
          </a>
        </div>
      )}
    </div>
  );

  const stepsiOS = [
    {
      header: "Add Robo Dobo as a contact on your phone",
      graphic: <ContactCard />,
    },
    {
      header: "On Whatsapp, press your friend's name at the top of their chat",
      graphic: <WrappedImg img={i1} isWeb={deviceType === "Web"} />,
    },
    {
      header: "Scroll down and tap 'Export Chat'",
      graphic: <WrappedImg img={i2} isWeb={deviceType === "Web"} />,
    },
    {
      header: "Select 'Attach Media' (for voice cloning)",
      graphic: <WrappedImg img={i3} isWeb={deviceType === "Web"} />,
    },
    {
      header: "Select Whatsapp",
      graphic: <WrappedImg img={i4} isWeb={deviceType === "Web"} />,
    },
    {
      header: "Search for and tap Robo Dobo from the list",
      graphic: <WrappedImg img={i5} isWeb={deviceType === "Web"} />,
    },
    {
      header: "Talk to your new friend just like you're used to, on Whatsapp",
      graphic: <WrappedImg img={loll} isWeb={deviceType === "Web"} />,
    },
  ];

  const stepsAndroid = [
    {
      header: "Add Robo Dobo as a contact on your phone",
      graphic: <ContactCard />,
    },
    {
      header:
        "On Whatsapp, press the three dots in the top right of your friend's chat, then press 'More'",
      graphic: <WrappedImg img={a1} isWeb={deviceType === "Web"} />,
    },
    {
      header: "Press 'Export Chat'",
      graphic: <WrappedImg img={a2} isWeb={deviceType === "Web"} />,
    },
    {
      header: "Select 'Attach Media' (for voice cloning)",
      graphic: <WrappedImg img={a3} isWeb={deviceType === "Web"} />,
    },
    {
      header: "Select Whatsapp",
      graphic: <WrappedImg img={a4} isWeb={deviceType === "Web"} />,
    },
    {
      header: "Search for and tap Robo Dobo from the list",
      graphic: <WrappedImg img={a5} isWeb={deviceType === "Web"} />,
    },
    {
      header: "Talk to your new friend just like you're used to, on Whatsapp",
      graphic: <WrappedImg img={loll} isWeb={deviceType === "Web"} />,
    },
  ];

  return (
    <div
      className="App"
      class="flex flex-col justify-center bg-gradient-to-b from-gradient-start-p to-gradient-end-p items-center"
    >
      <div
        class={`flex w-full ${
          deviceType === "Web" ? "flex-row py-16 " : "flex-col pt-8"
        } justify-center items-center bg-light-p px-8`}
      >
        <div class="flex flex-col max-w-2xl">
          <p class="text-5xl text-text-w font-inter font-[700] mb-1">
            Never say goodbye
          </p>
          <p
            class={`text-3xl text-text-g font-inter font-[300] pb-24 ${
              deviceType !== "Web" && "text-2xl pb-8"
            }`}
          >
            Make a digital clone of your loved one
          </p>
          {deviceType === "Web" ? (
            <>
              <QRCode size={300} value={vCard} />
              <p class="text-xl text-text-g font-inter font-[300] max-w-72 pt-4">
                Add <span class="text-text-w font-[400]">{phoneNum}</span> as a
                contact to get started
              </p>
            </>
          ) : (
            <>
              <a
                href={
                  deviceType === "iOS"
                    ? `${process.env.PUBLIC_URL}/vcard.vcf`
                    : `tel:+19176056982`
                }
                download
              >
                <button className="bg-gradient-to-r from-t-g-s to-t-g-e text-white p-2 rounded-lg w-full">
                  <p class="text-text-w font-inter font-[700] text-lg py-2">
                    Add contact
                  </p>
                </button>
              </a>
              <p class="text-xl text-text-g font-inter font-[300] max-w-72 py-2">
                Add <span class="text-text-w font-[400]">{phoneNum}</span> as a
                contact to get started
              </p>
            </>
          )}
        </div>
        <div class="flex flex-col">
          <img
            src={deviceType === "Web" ? phone : clipped}
            alt="Durn"
            class={`${deviceType === "Web" ? "w-96 rotate-12" : "mt-8"}`}
          />
        </div>
      </div>
      {os === "Web" && (
        <OSToggle
          selected={visibleTodo}
          setSelected={(t) => setVisibleTodo(t)}
        />
      )}
      <div class="flex flex-col justify-center items-center py-10 max-w-screen-md">
        {(visibleTodo === "iOS" ? stepsiOS : stepsAndroid).map((step, idx) => (
          <Step idx={idx + 1} isWeb={deviceType === "Web"} {...step} />
        ))}
      </div>
      <div class="flex flex-col bg-gradient-start-p p-8 items-center w-full justify-center items-center">
        <div class="max-w-3xl">
          <p class="text-text-w text-left font-inter font-[700] text-4xl">
            FAQ
          </p>
          <p class="text-text-w text-left font-inter font-[700] pt-4">
            Where does my data go?
          </p>
          <p class="text-text-w text-left font-inter font-[300]">
            No human ever sees your data. It is immediately deleted after being
            used to train your bot.
          </p>
          <p class="text-text-w text-left font-inter font-[700] pt-4">
            What if don't use Whatsapp?
          </p>
          <p class="text-text-w text-left font-inter font-[300]">
            The service only works for Whatsapp at the moment.
          </p>
          <p class="text-text-w text-left font-inter font-[700] pt-4">
            What if I want to stop using the service?
          </p>
          <p class="text-text-w text-left font-inter font-[300]">
            At any point, if you text "DELETE EVERYTHING" to our bot, your bot
            will be deleted. No questions asked.
          </p>
          <p class="text-text-w text-left font-inter font-[700] pt-4">
            How can I interact with my bot?
          </p>
          <p class="text-text-w text-left font-inter font-[300]">
            We currently support texting, and voice notes back and forth. Voice
            calling and picture generation are in active development.
          </p>
          <p class="text-text-w text-left font-inter font-[700] pt-4">
            How much does this cost?
          </p>
          <p class="text-text-w text-left font-inter font-[300]">
            The bots cost money to run. If the bills get too high, I may start
            charging to cover my costs.
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
