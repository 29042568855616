import React from "react";
import android from "./img/android.png";
import apple from "./img/apple.png";

const ToggleLogo = ({ selected, setSelected }) => {
  return (
    <div className="flex items-center justify-center pt-8">
      <img
        src={android}
        alt="Logo 1"
        className={`cursor-pointer pr-2 h-12 transition-all duration-300 ease-in-out ${
          selected === "Android" ? "opacity-100" : "opacity-25 grayscale"
        }`}
        onClick={() => setSelected("Android")}
      />
      <img
        src={apple}
        alt="Logo 2"
        className={`cursor-pointer pl-2 h-12 transition-all duration-300 ease-in-out ml-4 ${
          selected === "iOS" ? "opacity-100" : "opacity-25 grayscale"
        }`}
        onClick={() => setSelected("iOS")}
      />
    </div>
  );
};

export default ToggleLogo;
